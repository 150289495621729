import React, { useState, useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../utils/axios";

const LogsActivity = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const parkingId = admin.parking;
  const [parkingLogsActivity, setParkingLogsActivity] = useState([]);
  const [isLoadingLogsActivity, setIsLoadingLogsActivity] = useState(false);

  useEffect(() => {
    const getParkingLogsActivity = async () => {
      setIsLoadingLogsActivity(true);
      const res = await axios.get(`/parkings/${parkingId}/logs`);
      setParkingLogsActivity(res.data);
      setIsLoadingLogsActivity(false);
    };

    getParkingLogsActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      inputDate,
    );
    return formattedDate;
  }

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      {/* Card header */}
      <div className="items-center justify-between lg:flex">
        <div className="mb-4 lg:mb-0">
          <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
            Logs Activity
          </h3>
          <span className="text-base font-normal text-gray-500 dark:text-gray-400">
            This is a list of all the logs activity of your parking.
          </span>
        </div>
      </div>
      {isLoadingLogsActivity && (
        <div className="flex items-center justify-center">
          <AiOutlineLoading3Quarters className="animate-spin text-2xl text-blue-500 mr-2" />
          <span className="text-black dark:text-white">
            Fetching logs activity...
          </span>
        </div>
      )}
      {/* Table */}
      {!isLoadingLogsActivity && parkingLogsActivity.length > 0 && (
        <div className="flex flex-col mt-6">
          <div className="overflow-x-auto rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Log ID
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Full name
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Entry Date
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Exit Date
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Duration
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                    {parkingLogsActivity.length > 0 &&
                      parkingLogsActivity.map((log) => (
                        <tr
                          key={log._id}
                          className="bg-gray-50 dark:bg-gray-700"
                        >
                          <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {log._id}
                          </td>
                          <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                            from{" "}
                            <span className="font-semibold">
                              {log.user.firstName} {log.user.lastName}
                            </span>
                          </td>
                          <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {log.user.email}
                          </td>
                          <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
                            {formatDate(log.entryDate)}
                          </td>
                          <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
                            {formatDate(log.exitDate)}
                          </td>
                          <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
                            {log.duration}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLoadingLogsActivity && parkingLogsActivity.length === 0 && (
        <div className="flex items-center justify-center">
          <span className="text-black dark:text-white">
            No logs activity found.
          </span>
        </div>
      )}
    </div>
  );
};

export default LogsActivity;
