import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { TbPlus } from "react-icons/tb";
import axios from "../utils/axios";

const RFIDTags = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const parkingId = admin.parking;
  const [rfidTags, setRfidTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRfidTags = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/parkings/${parkingId}/tags`);
      if (data?.rfidTags && data.rfidTags.length > 0)
        setRfidTags(data.rfidTags);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    fetchRfidTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="items-center justify-between lg:flex">
        <div className="mb-4 lg:mb-0">
          <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
            RFID Tags
          </h3>
          <span className="text-base font-normal text-gray-500 dark:text-gray-400">
            This is a list of all the RFID tags in your parking.
          </span>
        </div>
        <div className="flex justify-end mb-4">
          <Link to="/rfid-tags/add">
            <button className="flex items-center px-4 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500">
              <TbPlus className="w-6 h-6 text-white transition duration-75 dark:text-white mr-2" />
              Add RFID Tag
            </button>
          </Link>
        </div>
      </div>
      {/* Table */}
      {isLoading && (
        <div className="flex items-center justify-center">
          <AiOutlineLoading3Quarters className="animate-spin text-2xl text-blue-500 mr-2" />
          <span className="text-black dark:text-white">
            Fetching RFID Tags...
          </span>
        </div>
      )}
      {!isLoading && rfidTags?.length > 0 && (
        <div className="flex flex-col mt-6">
          <div className="overflow-x-auto rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Assigned To
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Tag UID
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Is Suspended
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Is Reported
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Is In Use
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Creation Date
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Issuing Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                    {rfidTags?.map((rfidTag) => (
                      <tr key={rfidTag?._id}>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {rfidTag?.user?.email
                            ? rfidTag?.user?.email
                            : "Not Assigned"}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {rfidTag?.tagUid}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {rfidTag?.isSuspended ? "Suspended" : "Not Suspended"}
                        </td>

                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {rfidTag?.isReported ? "Reported" : "Not Reported"}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {rfidTag?.isInUse ? "In Use" : "Not In Use"}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {formatDate(rfidTag?.createdAt)}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {rfidTag?.issuedAt
                            ? formatDate(rfidTag?.issuedAt)
                            : "Not Issued"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RFIDTags;
