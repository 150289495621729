import { useState, useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../utils/axios";

const Transactions = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const parkingId = admin.parking;
  const [parkingPaymentHistory, setParkingPaymentHistory] = useState([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);

  useEffect(() => {
    const getParkingPaymentHistory = async () => {
      setIsLoadingTransactions(true);
      const res = await axios.get(`/parkings/${parkingId}/paymentHistory`);
      setParkingPaymentHistory(res.data);
      setIsLoadingTransactions(false);
    };

    getParkingPaymentHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      {/* Card header */}
      <div className="items-center justify-between lg:flex">
        <div className="mb-4 lg:mb-0">
          <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
            Transactions
          </h3>
          <span className="text-base font-normal text-gray-500 dark:text-gray-400">
            This is a list of all transactions of your parking.
          </span>
        </div>
      </div>
      {isLoadingTransactions && (
        <div className="flex items-center justify-center">
          <AiOutlineLoading3Quarters className="animate-spin text-2xl text-blue-500 mr-2" />
          <span className="text-black dark:text-white">
            Fetching transactions...
          </span>
        </div>
      )}
      {/* Table */}
      {!isLoadingTransactions && parkingPaymentHistory.length > 0 && (
        <div className="flex flex-col mt-6">
          <div className="overflow-x-auto rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Transaction ID
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Full name
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Paid amount
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                    {parkingPaymentHistory.length > 0 &&
                      parkingPaymentHistory.map((payment) => (
                        <tr
                          key={payment._id}
                          className="bg-gray-50 dark:bg-gray-700"
                        >
                          <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {payment._id}
                          </td>
                          <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                            from{" "}
                            <span className="font-semibold">
                              {payment.user.firstName} {payment.user.lastName}
                            </span>
                          </td>
                          <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {payment.user.email}
                          </td>
                          <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
                            {payment.amount} DZD
                          </td>
                          <td className="p-4 whitespace-nowrap">
                            {payment.isFullyPaid ? (
                              <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500">
                                Fully paid
                              </span>
                            ) : (
                              <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-red-400 border border-red-100 dark:border-red-500">
                                Partially paid
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLoadingTransactions && parkingPaymentHistory.length === 0 && (
        <div className="flex items-center justify-center">
          <span className="text-black dark:text-white">No transactions found.</span>
        </div>
      )}
    </div>
  );
};

export default Transactions;
