import { useState, useEffect } from "react";
import { TbPlus } from "react-icons/tb";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "../utils/axios";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const admin = JSON.parse(localStorage.getItem("admin"));
  const parkingId = admin.parking;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const formatPlateNumber = (plateNumber) => {
    const formattedPlateNumber = plateNumber.replace(
      /(\d{5})(\d{3})(\d{2})/,
      "$1 $2 $3"
    );
    return formattedPlateNumber;
  };

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/parkings/${parkingId}/users`);
      if (data?.users && data.users.length > 0) setUsers(data.users);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="items-center justify-between lg:flex">
        <div className="mb-4 lg:mb-0">
          <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
            Parking's Users
          </h3>
          <span className="text-base font-normal text-gray-500 dark:text-gray-400">
            This is a list of all the users registered in your parking.
          </span>
        </div>
        <div className="flex justify-end mb-4">
          <Link to="/users/add">
            <button className="flex items-center px-4 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500">
              <TbPlus className="w-6 h-6 text-white transition duration-75 dark:text-white mr-2" />
              Add User
            </button>
          </Link>
        </div>
      </div>
      {/* Table */}
      {isLoading && (
        <div className="flex items-center justify-center">
          <AiOutlineLoading3Quarters className="animate-spin text-2xl text-blue-500 mr-2" />
          <span className="text-black dark:text-white">Fetching users...</span>
        </div>
      )}
      {!isLoading && users.length > 0 && (
        <div className="flex flex-col mt-6">
          <div className="overflow-x-auto rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Vehicle Number
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Balance
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Registered Date
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                    {users.map((user) => (
                      <tr key={user?._id}>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {user?.firstName} {user?.lastName}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {user?.email}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {formatPlateNumber(user?.numberPlate)}
                        </td>

                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          DA {user?.balance}
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                          {formatDate(user?.createdAt)}
                        </td>
                        <td className="p-4 whitespace-nowrap">
                          <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500">
                            {user?.isBannded ? "Banned" : "Active"}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLoading && users.length === 0 && (
        <div className="flex items-center justify-center">
          <span className="text-black dark:text-white">No users found</span>
        </div>
      )}
    </div>
  );
};

export default Users;
