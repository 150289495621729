import { useState } from "react";
import axios from "../utils/axios";
import { useNavigate } from "react-router-dom";

const AddRFIDTag = () => {
  const navigate = useNavigate();
  
  const admin = JSON.parse(localStorage.getItem("admin"));
  const parkingId = admin.parking;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rfidTagUid, setRfidTagUid] = useState("");
  const [apiError, setApiError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setApiError(null);
    try {
      const { data } = await axios.post("/tags/add", {
        rfidTagUid,
        parkingId,
      });
      setIsSubmitting(false);
      setRfidTagUid("");
      navigate("/rfid-tags");
    } catch (err) {
      console.error(err.response.data.message);
      setApiError(err.response.data.message);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-4 m-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <form>
        <div className="mb-3">
          <label
            htmlFor="rfidTagUid"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            RFID Tag UID
          </label>
          <input
            type="text"
            id="rfidTagUid"
            name="rfidTagUid"
            className="bg-gray-50 border border-blue-500 disabled:border-gray-300 disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="RFID Tag UID"
            value={rfidTagUid || ""}
            onChange={(e) => setRfidTagUid(e.target.value)}
            disabled={isSubmitting}
            required
          />
          {apiError && (
            <p className="text-red-500 text-sm mt-1">
              {apiError}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="text-white disabled:bg-gray-300 disabled:cursor-not-allowed bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center disabled:dark:bg-gray-600 disabled:dark:hover:bg-gray-700 disabled:dark:focus:ring-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={handleSubmit}
          disabled={
            isSubmitting || !rfidTagUid || rfidTagUid.trim().length === 0
          }
        >
          {isSubmitting ? "Adding RFID Tag..." : "Add RFID Tag"}
        </button>
      </form>
    </div>
  );
};

export default AddRFIDTag;
