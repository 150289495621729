import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import axios from "../utils/axios";

const Home = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const parkingId = admin.parking;
  const [parking, setParking] = useState({});
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [showHourlyPriceModal, setShowHourlyPriceModal] = useState(false);
  const [hourlyPrice, setHourlyPrice] = useState(parking.hourlyPrice);
  const [isUpdatingHourlyPrice, setIsUpdatingHourlyPrice] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingTransactions(true);
      try {
        const [parkingRes, logsNumberRes, totalRevenueRes, paymentHistoryRes] =
          await Promise.all([
            axios.get(`/parkings/${parkingId}`),
            axios.get(`/parkings/${parkingId}/logsNumber`),
            axios.get(`/parkings/${parkingId}/totalRevenue`),
            axios.get(`/parkings/${parkingId}/paymentHistory`),
          ]);

        setParking((prev) => ({
          ...prev,
          ...{
            ...parkingRes.data.data,
            logsNumber: logsNumberRes.data,
            totalRevenue: totalRevenueRes.data,
            paymentHistory: paymentHistoryRes.data.slice(0, 4),
          },
        }));
        setHourlyPrice(parkingRes.data.data.hourlyPrice);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setIsLoadingTransactions(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHourlyPriceSubmit = async (e) => {
    e.preventDefault();
    setIsUpdatingHourlyPrice(true);
    try {
      await axios.put(`/parkings/${parkingId}/hourlyPrice`, {
        newHourlyPrice: hourlyPrice,
      });
      setParking((prev) => ({
        ...prev,
        ...{
          hourlyPrice,
        },
      }));
      setHourlyPrice(hourlyPrice);
      setShowHourlyPriceModal(false);
    } catch (error) {
      console.error("Error updating parking:", error.message);
    } finally {
      setIsUpdatingHourlyPrice(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 my-4 xl:grid-cols-5 xl:gap-4">
        <div className="m-2 bg-white border border-gray-200 rounded-lg shadow-sm xl:m-0 dark:border-gray-700 dark:bg-gray-800">
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="flex-auto p-4">
              <div className="flex flex-wrap">
                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 className="text-blueGray-400 dark:text-white uppercase font-bold text-xs">
                    Total Parking Spots
                  </h5>
                  <span className="font-bold text-xl dark:text-gray-400">
                    {parking.lots}
                  </span>
                </div>
                <div className="relative w-auto pl-4 flex-initial">
                  <div className="text-white p-2 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={64}
                      height={64}
                      viewBox="0 0 1.707 1.707"
                      style={{
                        enableBackground: "new 0 0 512 512",
                      }}
                      xmlSpace="preserve"
                    >
                      <path
                        fill="#dedfcf"
                        d="M.317 1.523a.04.04 0 0 1-.04-.04V.778a.04.04 0 1 1 .08 0v.705a.04.04 0 0 1-.04.04z"
                        data-original="#dedfcf"
                      />
                      <path
                        fill="#2e9cf5"
                        d="M.594.818H.04A.04.04 0 0 1 0 .778V.224a.04.04 0 0 1 .04-.04h.554a.04.04 0 0 1 .04.04v.554a.04.04 0 0 1-.04.04z"
                        data-original="#2e9cf5"
                      />
                      <path
                        fill="#99a4ac"
                        d="M.43 1.523H.204a.04.04 0 1 1 0-.08h.224a.04.04 0 1 1 0 .08z"
                        data-original="#99a4ac"
                      />
                      <path
                        fill="#fbfbfb"
                        d="M.396.534a.02.02 0 0 0 .02-.02V.357a.02.02 0 0 0-.02-.02H.239a.02.02 0 0 0-.02.02v.288a.02.02 0 1 0 .04 0V.534h.137zM.259.494V.377h.117v.117z"
                        data-original="#fbfbfb"
                      />
                      <path
                        fill="#ff700d"
                        d="M1.56.96H.681A.04.04 0 0 1 .644.906l.15-.403A.04.04 0 0 1 .83.477h.58a.04.04 0 0 1 .037.026l.15.403A.04.04 0 0 1 1.56.96zM.74.88h.764l-.12-.323H.858L.74.88z"
                        data-original="#ff700d"
                      />
                      <path
                        fill="#8ebbe5"
                        d="M1.541.906h-.84L.84.53h.56z"
                        data-original="#8ebbe5"
                      />
                      <path
                        fill="#9ed0fe"
                        d="m1.503.88-.12-.323H.858L.74.88z"
                        data-original="#9ed0fe"
                      />
                      <path
                        fill="#e6650c"
                        d="M1.56.96H.681A.04.04 0 0 1 .644.906l.02-.053h.914l.02.053A.04.04 0 0 1 1.56.96z"
                        data-original="#e6650c"
                      />
                      <path
                        fill="#8ebbe5"
                        d="m1.531.88-.01-.027h-.8L.71.88z"
                        data-original="#8ebbe5"
                      />
                      <path
                        fill="#4d4d4d"
                        d="M1.438 1.523h-.024a.14.14 0 0 1-.14-.14v-.086h.303v.086a.14.14 0 0 1-.14.14zm-.611 0H.804a.14.14 0 0 1-.14-.14V1.3h.303v.083a.14.14 0 0 1-.14.14z"
                        data-original="#4d4d4d"
                      />
                      <path
                        fill="#454545"
                        d="M1.274 1.35v-.053h.303v.053zm-.61 0V1.3h.303v.05z"
                        data-original="#454545"
                      />
                      <path
                        fill="#ff700d"
                        d="M1.667 1.323H.575a.04.04 0 0 1-.04-.044L.58.915A.04.04 0 0 1 .62.88h1.003a.04.04 0 0 1 .04.035l.044.359a.04.04 0 0 1-.039.05z"
                        data-original="#ff700d"
                      />
                      <path
                        d="M.857 1.122a.02.02 0 1 0 0-.04h-.09a.02.02 0 1 0 0 .04zm.617 0a.02.02 0 1 0 0-.04h-.09a.02.02 0 1 0 0 .04h.09z"
                        data-original="#fbfbfb"
                        fill="#fbfbfb"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="m-2 bg-white border border-gray-200 rounded-lg shadow-sm xl:m-0 dark:border-gray-700 dark:bg-gray-800">
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="flex-auto p-4">
              <div className="flex flex-wrap">
                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 className="text-blueGray-400 dark:text-white uppercase font-bold text-xs">
                    Parking Sessions
                  </h5>
                  <span className="font-bold text-xl dark:text-gray-400">
                    {parking.logsNumber}
                  </span>
                </div>
                <div className="relative w-auto pl-4 flex-initial">
                  <div className="text-white p-2 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={64}
                      height={64}
                      viewBox="0 0 1580 1580"
                      style={{
                        enableBackground: "new 0 0 512 512",
                      }}
                      xmlSpace="preserve"
                    >
                      <path
                        fill="#5593bf"
                        d="M1509.86 342.62 790 22 70.14 342.62v167.17h1439.72z"
                        data-original="#5593bf"
                      />
                      <path
                        fill="#d1ddeb"
                        d="M178.18 616.46h1223.64v106.68H178.18z"
                        data-original="#d1ddeb"
                      />
                      <path
                        fill="#dfebfa"
                        d="M178.18 509.79h1223.64v106.68H178.18z"
                        data-original="#dfebfa"
                      />
                      <path
                        fill="#67b2e6"
                        d="M70.14 509.79h108.04V1558H70.14zm1331.68 0h108.04V1558h-108.04z"
                        data-original="#67b2e6"
                      />
                      <path
                        d="M467.29 1558h-39.88c-23.45 0-42.47-19.01-42.47-42.47v-101.16h124.81v101.16c0 23.46-19.01 42.47-42.46 42.47zm685.3 0h-39.88c-23.45 0-42.47-19.01-42.47-42.47v-101.16h124.81v101.16c.01 23.46-19.01 42.47-42.46 42.47z"
                        data-original="#465a65"
                        fill="#465a65"
                      />
                      <path
                        fill="#ff725c"
                        d="m328.39 1192.97 163.5-218.25a160.799 160.799 0 0 1 128.69-64.39h338.85c50.64 0 98.32 23.86 128.69 64.39l163.5 218.25a80.39 80.39 0 0 1 16.05 48.2v166.27c0 28.37-23 51.36-51.36 51.36H363.69c-28.37 0-51.36-23-51.36-51.36v-166.27c0-17.38 5.63-34.29 16.06-48.2z"
                        data-original="#ff725c"
                      />
                      <path
                        fill="#cc5b4a"
                        d="M924.64 1357.17H655.36c-10.61 0-19.94-7.03-22.86-17.23l-11.19-39.09c-4.35-15.19 7.05-30.32 22.86-30.32h291.66c15.8 0 27.21 15.13 22.86 30.32l-11.19 39.09c-2.93 10.2-12.25 17.23-22.86 17.23z"
                        data-original="#cc5b4a"
                      />
                      <path
                        fill="#f7e07f"
                        d="m1153.92 1350.41-61.92-3.08c-17.84-.89-31.86-15.62-31.86-33.48 0-17.87 14.01-32.59 31.86-33.48l61.92-3.08c19.15-.95 35.19 14.31 35.19 33.48v6.17c0 19.16-16.04 34.43-35.19 33.47zm-727.84 0 61.92-3.08c17.84-.89 31.86-15.62 31.86-33.48 0-17.87-14.01-32.59-31.86-33.48l-61.92-3.08c-19.15-.95-35.19 14.31-35.19 33.48v6.17c0 19.16 16.04 34.43 35.19 33.47z"
                        data-original="#f7e07f"
                      />
                      <path
                        fill="#bbecfe"
                        d="M537.08 1139.27c-18.25 0-35.99-3.72-52.71-11.05l-53.04-23.27 84.25-112.46c12.15-16.22 28.16-29.59 46.29-38.67 18.13-9.07 38.42-13.87 58.69-13.87h338.85c20.27 0 40.56 4.79 58.69 13.87 18.13 9.07 34.13 22.44 46.29 38.66l84.25 112.46-53.04 23.27c-16.72 7.33-34.45 11.05-52.7 11.05H537.08z"
                        data-original="#bbecfe"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="m-2 bg-white border border-gray-200 rounded-lg shadow-sm xl:m-0 dark:border-gray-700 dark:bg-gray-800">
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="flex-auto p-4">
              <div className="flex flex-wrap">
                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 className="text-blueGray-400 dark:text-white uppercase font-bold text-xs">
                    Parking Revenue
                  </h5>
                  <span className="font-bold text-xl dark:text-gray-400">
                    {parking.totalRevenue &&
                      parking.totalRevenue.toFixed(2) + " DZD"}
                  </span>
                </div>
                <div className="relative w-auto pl-4 flex-initial">
                  <div className="text-white p-2 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={64}
                      height={64}
                      viewBox="0 0 64 64"
                      style={{
                        enableBackground: "new 0 0 512 512",
                      }}
                      xmlSpace="preserve"
                    >
                      <path
                        fill="#e2ca96"
                        d="M49 29.5a1 1 0 0 1-.71-.29c-2.66-2.67-8.24-2.71-8.29-2.71a1 1 0 0 1 0-2c.26 0 6.45 0 9.71 3.29a1 1 0 0 1 0 1.42 1 1 0 0 1-.71.29zm-34 0a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42c3.26-3.25 9.45-3.29 9.71-3.29a1 1 0 0 1 0 2c-.05 0-5.63 0-8.29 2.71a1 1 0 0 1-.71.29z"
                        data-original="#e2ca96"
                      />
                      <path
                        fill="#ea4450"
                        d="M39 28.5h-5l-2-16a18.5 18.5 0 0 1 12 0c-2 7-3 10-5 16z"
                        data-original="#ea4450"
                      />
                      <path
                        fill="#ce293d"
                        d="m40 25.49-1 3h-5l-2-16a18.56 18.56 0 0 1 6-1h.25z"
                        data-original="#ce293d"
                      />
                      <path
                        fill="#ea4450"
                        d="M25 28.5h5l2-16a18.5 18.5 0 0 0-12 0c2 7 3 10 5 16z"
                        data-original="#ea4450"
                      />
                      <path
                        fill="#ce293d"
                        d="M27 24.5c-1.66-5-2.63-7.9-4.06-12.74a18.33 18.33 0 0 0-2.94.74c2 7 3 10 5 16h5l.5-4z"
                        data-original="#ce293d"
                      />
                      <path
                        fill="#ea4450"
                        d="M36 23.5h-8l-2-16a18.5 18.5 0 0 1 12 0l-2 16z"
                        data-original="#ea4450"
                      />
                      <path
                        fill="#ce293d"
                        d="M32.77 18.5a2 2 0 0 1-2-1.75L29.52 6.67A19.12 19.12 0 0 0 26 7.5l2 16h8c.21-1.67.42-3.33.62-5z"
                        data-original="#ce293d"
                      />
                      <path
                        fill="#ea4450"
                        d="M52 50c0 11-9 10.5-20 10.5S12 61 12 50s9-26.5 20-26.5S52 39 52 50z"
                        data-original="#ea4450"
                      />
                      <path
                        fill="#ce293d"
                        d="M51.48 54c-2.06 6.9-9.99 6.5-19.48 6.5-11 0-20 .5-20-10.5 0-9.54 6.66-22.35 15.6-25.68C23 29.81 20 37.63 20 44c0 11 9 10.5 20 10.5a74.22 74.22 0 0 0 11.48-.5z"
                        data-original="#ce293d"
                      />
                      <rect
                        width={20}
                        height={4}
                        x={22}
                        y={21.5}
                        fill="#f2e6c7"
                        rx={2}
                        data-original="#f2e6c7"
                      />
                      <path
                        fill="#e2ca96"
                        d="M26 21.5h-2a2 2 0 0 0 0 4h16a2 2 0 0 0 2-2H28a2 2 0 0 1-2-2z"
                        data-original="#e2ca96"
                      />
                      <path
                        fill="#fbb03b"
                        d="M32 35.5a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm2 10h-4v-4h4z"
                        data-original="#fbb03b"
                      />
                      <path
                        fill="#f7931e"
                        d="M39.58 46.06a8 8 0 1 1-12.16-9.12 8 8 0 0 0 12.16 9.12z"
                        data-original="#f7931e"
                      />
                      <path
                        fill="#fbb03b"
                        d="M50 11.5a1 1 0 0 1-1-1v-6a1 1 0 0 1 2 0v6a1 1 0 0 1-1 1z"
                        data-original="#fbb03b"
                      />
                      <path
                        fill="#fbb03b"
                        d="M53 8.5h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"
                        data-original="#fbb03b"
                      />
                      <g fill="#ea4450">
                        <path
                          d="M55 23.5a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm-41-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 2 0v6a1 1 0 0 1-1 1z"
                          data-original="#ea4450"
                        />
                        <path
                          d="M17 10.5h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"
                          data-original="#ea4450"
                        />
                      </g>
                      <path
                        fill="#fbb03b"
                        d="M9 32.5a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
                        data-original="#fbb03b"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="m-2 bg-white border border-gray-200 rounded-lg shadow-sm xl:m-0 dark:border-gray-700 dark:bg-gray-800">
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="flex-auto p-4">
              <div className="flex flex-wrap">
                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 className="text-blueGray-400 dark:text-white uppercase font-bold text-xs">
                    Available Parking Spots
                  </h5>
                  <span className="font-bold text-xl dark:text-gray-400">
                    {parking.availableLots}
                  </span>
                </div>
                <div className="relative w-auto pl-4 flex-initial">
                  <div className="text-white p-2 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={64}
                      height={64}
                      viewBox="0 0 1.707 1.707"
                      style={{
                        enableBackground: "new 0 0 512 512",
                      }}
                      xmlSpace="preserve"
                    >
                      <path
                        fill="#dedfcf"
                        d="M.317 1.523a.04.04 0 0 1-.04-.04V.778a.04.04 0 1 1 .08 0v.705a.04.04 0 0 1-.04.04z"
                        data-original="#dedfcf"
                      />
                      <path
                        fill="#2e9cf5"
                        d="M.594.818H.04A.04.04 0 0 1 0 .778V.224a.04.04 0 0 1 .04-.04h.554a.04.04 0 0 1 .04.04v.554a.04.04 0 0 1-.04.04z"
                        data-original="#2e9cf5"
                      />
                      <path
                        fill="#99a4ac"
                        d="M.43 1.523H.204a.04.04 0 1 1 0-.08h.224a.04.04 0 1 1 0 .08z"
                        data-original="#99a4ac"
                      />
                      <path
                        fill="#fbfbfb"
                        d="M.396.534a.02.02 0 0 0 .02-.02V.357a.02.02 0 0 0-.02-.02H.239a.02.02 0 0 0-.02.02v.288a.02.02 0 1 0 .04 0V.534h.137zM.259.494V.377h.117v.117z"
                        data-original="#fbfbfb"
                      />
                      <path
                        fill="#ff700d"
                        d="M1.56.96H.681A.04.04 0 0 1 .644.906l.15-.403A.04.04 0 0 1 .83.477h.58a.04.04 0 0 1 .037.026l.15.403A.04.04 0 0 1 1.56.96zM.74.88h.764l-.12-.323H.858L.74.88z"
                        data-original="#ff700d"
                      />
                      <path
                        fill="#8ebbe5"
                        d="M1.541.906h-.84L.84.53h.56z"
                        data-original="#8ebbe5"
                      />
                      <path
                        fill="#9ed0fe"
                        d="m1.503.88-.12-.323H.858L.74.88z"
                        data-original="#9ed0fe"
                      />
                      <path
                        fill="#e6650c"
                        d="M1.56.96H.681A.04.04 0 0 1 .644.906l.02-.053h.914l.02.053A.04.04 0 0 1 1.56.96z"
                        data-original="#e6650c"
                      />
                      <path
                        fill="#8ebbe5"
                        d="m1.531.88-.01-.027h-.8L.71.88z"
                        data-original="#8ebbe5"
                      />
                      <path
                        fill="#4d4d4d"
                        d="M1.438 1.523h-.024a.14.14 0 0 1-.14-.14v-.086h.303v.086a.14.14 0 0 1-.14.14zm-.611 0H.804a.14.14 0 0 1-.14-.14V1.3h.303v.083a.14.14 0 0 1-.14.14z"
                        data-original="#4d4d4d"
                      />
                      <path
                        fill="#454545"
                        d="M1.274 1.35v-.053h.303v.053zm-.61 0V1.3h.303v.05z"
                        data-original="#454545"
                      />
                      <path
                        fill="#ff700d"
                        d="M1.667 1.323H.575a.04.04 0 0 1-.04-.044L.58.915A.04.04 0 0 1 .62.88h1.003a.04.04 0 0 1 .04.035l.044.359a.04.04 0 0 1-.039.05z"
                        data-original="#ff700d"
                      />
                      <path
                        d="M.857 1.122a.02.02 0 1 0 0-.04h-.09a.02.02 0 1 0 0 .04zm.617 0a.02.02 0 1 0 0-.04h-.09a.02.02 0 1 0 0 .04h.09z"
                        data-original="#fbfbfb"
                        fill="#fbfbfb"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="m-2 bg-white border border-gray-200 rounded-lg shadow-sm xl:m-0 dark:border-gray-700 dark:bg-gray-800">
          <div className="relative flex flex-col min-w-0 break-words">
            <div className="flex-auto p-4">
              <div className="flex flex-wrap">
                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                  <h5 className="text-blueGray-400 dark:text-white uppercase font-bold text-xs">
                    Parking Hourly Price
                  </h5>
                  <div className="flex flex-row items-center">
                    <span className="font-bold text-xl dark:text-gray-400 mr-1">
                      {parking.hourlyPrice &&
                        parking.hourlyPrice + " DZD"}
                    </span>
                    {parking.hourlyPrice && (
                      <CiEdit
                        className="w-6 h-6 text-gray-500 cursor-pointer hover:text-gray-700"
                        onClick={() => setShowHourlyPriceModal(true)}
                      />
                    )}
                  </div>
                </div>
                {showHourlyPriceModal && (
                  <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                      <div className="relative w-auto my-6 mx-auto max-w-3xl p-4 m-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 bg-gray-50 dark:bg-gray-900">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-gray-800 outline-none focus:outline-none">
                          <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-lg font-semibold text-black dark:text-white">
                              Change the hourly price of your parking
                            </h3>
                            <button
                              className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={() => setShowHourlyPriceModal(false)}
                            >
                              <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                              </span>
                            </button>
                          </div>
                          {/*body*/}
                          <div className="relative p-6 flex-auto">
                            <form>
                              <div className="mb-3">
                                <label
                                  htmlFor="hourlyPrice"
                                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                  Hourly Price
                                </label>
                                <input
                                  type="number"
                                  id="hourlyPrice"
                                  name="hourlyPrice"
                                  value={hourlyPrice}
                                  onChange={(e) => {
                                    const value = Number(e.target.value);
                                    if (value >= 0) {
                                      setHourlyPrice(value);
                                    }
                                  }}
                                  min={0}
                                  className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                  placeholder="Hourly Price"
                                  required
                                />
                              </div>
                            </form>
                          </div>
                          {/*footer*/}
                          <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setShowHourlyPriceModal(false)}
                            >
                              Close
                            </button>
                            <button
                              className="text-white disabled:bg-gray-300 disabled:cursor-not-allowed bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={handleHourlyPriceSubmit}
                              disabled={
                                hourlyPrice === parking.hourlyPrice ||
                                !hourlyPrice ||
                                isUpdatingHourlyPrice
                              }
                            >
                              {isUpdatingHourlyPrice
                                ? "Updating hourly price..."
                                : "Update hourly price"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30 dark:bg-black/30 opacity-90"></div>
                  </>
                )}
                <div className="relative w-auto pl-4 flex-initial">
                  <div className="text-white p-2 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 512 512"
                    >
                      <circle
                        cx="222.28"
                        cy="227.648"
                        r="208.957"
                        fill="#f9f6f9"
                        data-original="#f9f6f9"
                      ></circle>
                      <path
                        fill="#dddaec"
                        d="M321.18 43.551c15.851 29.444 24.861 63.117 24.861 98.9 0 115.404-93.553 208.957-208.957 208.957-35.783 0-69.456-9.009-98.9-24.861 35.271 65.516 104.475 110.057 184.096 110.057 115.404 0 208.957-93.553 208.957-208.957 0-79.62-44.541-148.825-110.057-184.096z"
                        data-original="#dddaec"
                      ></path>
                      <path
                        fill="#e7a561"
                        d="M222.28 449.928c-59.373 0-115.193-23.121-157.176-65.105C23.121 342.84 0 287.02 0 227.648S23.121 112.455 65.105 70.472C107.088 28.488 162.907 5.367 222.28 5.367s115.193 23.121 157.176 65.105c41.984 41.983 65.105 97.803 65.105 157.176s-23.121 115.193-65.105 157.176c-41.983 41.983-97.803 65.104-157.176 65.104zm0-410.548c-103.811 0-188.268 84.457-188.268 188.268s84.457 188.268 188.268 188.268 188.268-84.457 188.268-188.268S326.091 39.38 222.28 39.38z"
                        data-original="#e7a561"
                      ></path>
                      <path
                        fill="#e4936b"
                        d="M379.456 70.472a226.787 226.787 0 00-9.617-9.053c36.233 40.71 56.051 92.608 56.051 147.558 0 59.373-23.121 115.193-65.105 157.175-41.983 41.984-97.803 65.105-157.176 65.105-54.95 0-106.848-19.818-147.558-56.051a226.787 226.787 0 009.053 9.617c41.983 41.984 97.803 65.105 157.175 65.105 59.373 0 115.193-23.121 157.176-65.105 41.984-41.983 65.105-97.803 65.105-157.175s-23.121-115.193-65.104-157.176z"
                        data-original="#e4936b"
                      ></path>
                      <path
                        fill="#3b66c1"
                        d="M224.016 103.307a7.49 7.49 0 01-7.489-7.489V75.25a7.49 7.49 0 0114.978 0v20.569a7.488 7.488 0 01-7.489 7.488zm66.408 17.798a7.489 7.489 0 01-6.479-11.235l10.285-17.814a7.487 7.487 0 0110.23-2.741 7.489 7.489 0 012.741 10.23l-10.285 17.814a7.485 7.485 0 01-6.492 3.746zm48.632 48.618a7.483 7.483 0 01-6.492-3.746 7.489 7.489 0 012.741-10.23l17.814-10.285a7.487 7.487 0 0110.23 2.741 7.489 7.489 0 01-2.741 10.23l-17.814 10.285a7.456 7.456 0 01-3.738 1.005zm38.358 66.413h-20.569a7.49 7.49 0 010-14.978h20.569a7.49 7.49 0 010 14.978zm-20.558 76.7a7.456 7.456 0 01-3.738-1.005l-17.814-10.285a7.489 7.489 0 01-2.741-10.23 7.487 7.487 0 0110.23-2.741l17.814 10.285a7.489 7.489 0 01-3.751 13.976zm-56.133 56.148a7.483 7.483 0 01-6.492-3.746l-10.285-17.814a7.489 7.489 0 1112.971-7.489l10.285 17.814a7.489 7.489 0 01-6.479 11.235zm-153.412 0a7.489 7.489 0 01-6.479-11.235l10.285-17.814a7.487 7.487 0 0110.23-2.741 7.489 7.489 0 012.741 10.23l-10.285 17.814a7.485 7.485 0 01-6.492 3.746zm-56.134-56.148a7.483 7.483 0 01-6.492-3.746 7.489 7.489 0 012.741-10.23l17.814-10.285a7.488 7.488 0 0110.23 2.741 7.489 7.489 0 01-2.741 10.23l-17.814 10.285a7.443 7.443 0 01-3.738 1.005zm.011-76.7H70.619a7.49 7.49 0 010-14.978h20.569a7.49 7.49 0 010 14.978zm17.789-66.413a7.456 7.456 0 01-3.738-1.005l-17.814-10.285a7.489 7.489 0 01-2.741-10.23 7.486 7.486 0 0110.23-2.741l17.814 10.285a7.489 7.489 0 01-3.751 13.976zm48.632-48.618a7.483 7.483 0 01-6.492-3.746l-10.285-17.814a7.489 7.489 0 012.741-10.23 7.488 7.488 0 0110.23 2.741l10.285 17.814a7.489 7.489 0 01-6.479 11.235z"
                        data-original="#3b66c1"
                      ></path>
                      <path
                        fill="#3b66c1"
                        d="M224.016 103.307a7.49 7.49 0 01-7.489-7.489V75.25a7.49 7.49 0 0114.978 0v20.569a7.488 7.488 0 01-7.489 7.488z"
                        data-original="#3b66c1"
                      ></path>
                      <path
                        fill="#dd636e"
                        d="M329.073 123.312a7.49 7.49 0 00-10.592 0l-30.03 30.029a26.902 26.902 0 00-13.095-3.374c-7.228 0-14.023 2.815-19.135 7.926-8.713 8.714-10.222 21.937-4.544 32.222l-16.586 16.586a7.49 7.49 0 0010.592 10.591l16.578-16.577a26.903 26.903 0 0013.096 3.374c7.229 0 14.023-2.815 19.135-7.926 8.713-8.713 10.222-21.937 4.544-32.222l30.038-30.037a7.492 7.492 0 00-.001-10.592zm-45.174 62.259c-2.282 2.282-5.316 3.539-8.543 3.539s-6.261-1.257-8.543-3.539c-4.711-4.711-4.711-12.376 0-17.088 2.355-2.356 5.449-3.533 8.543-3.533s6.188 1.177 8.543 3.533c4.711 4.712 4.711 12.378 0 17.088z"
                        data-original="#dd636e"
                      ></path>
                      <path
                        fill="#2c4d66"
                        d="M210.21 235.136h-79.757a7.49 7.49 0 010-14.978h79.757a7.49 7.49 0 010 14.978z"
                        data-original="#2c4d66"
                      ></path>
                      <path
                        fill="#3b66c1"
                        d="M114.541 231.113l31.823 18.373c2.667 1.54 6-.385 6-3.464v-36.745c0-3.079-3.333-5.004-6-3.464l-31.823 18.373c-2.667 1.538-2.667 5.387 0 6.927z"
                        data-original="#3b66c1"
                      ></path>
                      <path
                        fill="#2c4d66"
                        d="M223.017 384.845a7.49 7.49 0 01-7.489-7.489V247.76c0-4.136 3.352-7.489 7.489-7.489s7.489 3.353 7.489 7.489v129.596a7.49 7.49 0 01-7.489 7.489z"
                        data-original="#2c4d66"
                      ></path>
                      <path
                        fill="#3b66c1"
                        d="M226.482 393.267l18.373-31.823c1.54-2.667-.385-6-3.464-6h-36.745c-3.079 0-5.004 3.333-3.464 6l18.373 31.823c1.539 2.667 5.388 2.667 6.927 0z"
                        data-original="#3b66c1"
                      ></path>
                      <path
                        fill="#30579d"
                        d="M241.401 355.445h-9.306l-17.189 29.772 4.653 8.059c1.537 2.663 5.381 2.663 6.918 0l18.383-31.84c1.537-2.663-.384-5.991-3.459-5.991zm-95.029-149.638l-8.616 4.975v33.734l8.616 4.975c2.663 1.537 5.991-.384 5.991-3.459v-36.766c0-3.075-3.328-4.997-5.991-3.459z"
                        data-original="#30579d"
                      ></path>
                      <circle
                        cx="222.28"
                        cy="227.648"
                        r="30.719"
                        fill="#3b66c1"
                        data-original="#3b66c1"
                      ></circle>
                      <path
                        fill="#30579d"
                        d="M233.185 198.928a30.632 30.632 0 011.999 10.905c0 16.966-13.753 30.719-30.719 30.719-3.841 0-7.515-.711-10.905-1.999 4.4 11.581 15.596 19.814 28.72 19.814 16.966 0 30.719-13.753 30.719-30.719 0-13.125-8.233-24.32-19.814-28.72z"
                        data-original="#30579d"
                      ></path>
                      <circle
                        cx="403.738"
                        cy="275.283"
                        r="85.584"
                        fill="#ffd064"
                        data-original="#ffd064"
                      ></circle>
                      <path
                        fill="#ffc250"
                        d="M450.885 203.864c8.945 13.522 14.166 29.722 14.166 47.148 0 47.267-38.317 85.584-85.584 85.584-17.426 0-33.626-5.221-47.148-14.165 15.317 23.155 41.578 38.436 71.418 38.436 47.267 0 85.584-38.317 85.584-85.584.001-29.841-15.281-56.102-38.436-71.419z"
                        data-original="#ffc250"
                      ></path>
                      <path
                        fill="#ffe07d"
                        d="M403.738 368.356c-51.321 0-93.073-41.752-93.073-93.073 0-51.32 41.752-93.073 93.073-93.073s93.073 41.752 93.073 93.073c-.001 51.32-41.752 93.073-93.073 93.073zm0-171.168c-43.061 0-78.095 35.033-78.095 78.095s35.033 78.095 78.095 78.095 78.095-35.033 78.095-78.095-35.034-78.095-78.095-78.095z"
                        data-original="#ffe07d"
                      ></path>
                      <path
                        fill="#ebb044"
                        d="M404.226 338.373c4.099-.122 7.26-3.698 7.26-7.799v-5.28c12.702-3.169 20.844-13.995 22.638-24.67 2.336-13.898-4.922-25.979-18.493-30.776a273.013 273.013 0 01-4.145-1.5v-24.619c2.821 1.024 4.463 2.412 4.597 2.528l-.078-.07.268.185c7.325 5.067 15.82-4.553 9.887-11.195-.337-.311-5.762-5.2-14.674-6.931v-4.042c0-4.101-3.161-7.677-7.26-7.799a7.488 7.488 0 00-7.718 7.486v4.881c-.507.13-1.018.269-1.535.424-9.003 2.712-15.728 10.4-17.549 20.063-1.67 8.863 1.204 17.437 7.501 22.376 3.003 2.355 6.667 4.514 11.584 6.761v32.539c-3.485-.473-6.125-1.615-10.165-4.236-3.418-2.217-8.108-1.542-10.457 1.786a7.49 7.49 0 002.015 10.591c7.276 4.76 12.397 6.409 18.607 6.928v4.884a7.487 7.487 0 007.717 7.485zm15.128-40.232c-.716 4.258-3.384 8.745-7.868 11.205v-25.032c8.566 3.494 8.381 10.78 7.868 13.827zm-25.185-38.293c-1.852-1.452-2.647-4.521-2.026-7.816.465-2.462 1.842-5.316 4.366-7.125v16.568a27.935 27.935 0 01-2.34-1.627z"
                        data-original="#ebb044"
                      ></path>
                      <circle
                        cx="455.295"
                        cy="449.928"
                        r="49.216"
                        fill="#ffd064"
                        data-original="#ffd064"
                      ></circle>
                      <path
                        fill="#ffc250"
                        d="M473.9 404.362a49.051 49.051 0 013.65 18.606c0 27.181-22.035 49.216-49.216 49.216a49.055 49.055 0 01-18.606-3.65c7.34 17.955 24.97 30.61 45.566 30.61 27.181 0 49.216-22.035 49.216-49.216.001-20.596-12.655-38.227-30.61-45.566z"
                        data-original="#ffc250"
                      ></path>
                      <path
                        fill="#ffe07d"
                        d="M455.295 506.633c-31.267 0-56.705-25.438-56.705-56.705s25.438-56.705 56.705-56.705S512 418.66 512 449.928s-25.438 56.705-56.705 56.705zm0-98.432c-23.009 0-41.728 18.718-41.728 41.727s18.718 41.727 41.728 41.727c23.009 0 41.728-18.719 41.728-41.727-.001-23.009-18.719-41.727-41.728-41.727z"
                        data-original="#ffe07d"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        {/* Card header */}
        <div className="items-center justify-between lg:flex">
          <div className="mb-4 lg:mb-0">
            <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
              Latest transactions
            </h3>
            <span className="text-base font-normal text-gray-500 dark:text-gray-400">
              This is a list of latest transactions
            </span>
          </div>
        </div>
        {isLoadingTransactions && (
          <div className="flex items-center justify-center">
            <AiOutlineLoading3Quarters className="animate-spin text-2xl text-blue-500 mr-2" />
            <span className="text-black dark:text-white">
              Fetching transactions...
            </span>
          </div>
        )}
        {/* Table */}
        {!isLoadingTransactions && parking.paymentHistory?.length > 0 && (
          <div className="flex flex-col mt-6">
            <div className="overflow-x-auto rounded-lg">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                        >
                          Transaction ID
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                        >
                          Full name
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                        >
                          Paid amount
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800">
                      {parking.paymentHistory &&
                        parking.paymentHistory.length > 0 &&
                        parking.paymentHistory.map((payment) => (
                          <tr
                            key={payment._id}
                            className="bg-gray-50 dark:bg-gray-700"
                          >
                            <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                              {payment._id}
                            </td>
                            <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                              from{" "}
                              <span className="font-semibold">
                                {payment.user.firstName} {payment.user.lastName}
                              </span>
                            </td>
                            <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                              {payment.user.email}
                            </td>
                            <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-white">
                              {payment.amount} DZD
                            </td>
                            <td className="p-4 whitespace-nowrap">
                              {payment.isFullyPaid ? (
                                <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 border border-green-100 dark:border-green-500">
                                  Fully paid
                                </span>
                              ) : (
                                <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-red-400 border border-red-100 dark:border-red-500">
                                  Partially paid
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoadingTransactions && parking.paymentHistory?.length === 0 && (
          <div className="flex items-center justify-center">
            <span className="text-black dark:text-white">
              No transactions found.
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
