import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { TbFileInvoice } from "react-icons/tb";
import { IoAlertCircleOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi";
import { BiRfid } from "react-icons/bi";

const SideBar = ({ isSidebarOpen }) => {
  return (
    <aside
      id="sidebar"
      className={`fixed top-0 left-0 z-20 flex flex-col flex-shrink-0 w-64 h-full pt-16 font-normal duration-75 transition-width ${ isSidebarOpen ? "flex" : "hidden" } lg:flex`}
      aria-label="Sidebar"
    >
      <div className="relative flex flex-col flex-1 min-h-0 pt-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
          <div className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            <ul className="pb-2 space-y-2">
              <li>
                <Link
                  to="/"
                  className="flex items-center p-2 text-[14px] text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
                >
                  <RxDashboard className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white mr-2" />
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/users"
                  className="flex items-center p-2 text-[14px] text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
                >
                  <HiOutlineUsers className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white mr-2" />
                  Users
                </Link>
              </li>
              <li>
                <Link
                  to="/transactions"
                  className="flex items-center p-2 text-[14px] text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
                >
                  <TbFileInvoice className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white mr-2" />
                  Transactions
                </Link>
              </li>
              <li>
                <Link
                  to="/logs-activity"
                  className="flex items-center p-2 text-[14px] text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
                >
                  <IoAlertCircleOutline className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white mr-2" />
                  Logs Activity
                </Link>
              </li>
              <li>
                <Link
                  to="/rfid-tags"
                  className="flex items-center p-2 text-[14px] text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
                >
                  <BiRfid className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white mr-2" />
                  RFID Tags
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
