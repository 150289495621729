import { useState, useEffect } from "react";
import axios from "../utils/axios";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const navigate = useNavigate();

  const admin = JSON.parse(localStorage.getItem("admin"));
  const parkingId = admin.parking;

  const [rfidTags, setRfidTags] = useState([]);
  const [fetchingRfidTags, setFetchingRfidTags] = useState(false);
  const [formData, setFormData] = useState({
    parking: parkingId,
  });
  const [inputErrors, setInputErrors] = useState({});
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setApiErrorMessage(null);
    if (rfidTags.length === 0) return;
    if (e.target.name === "firstName") {
      if (e.target.value.trim().length === 0) {
        setInputErrors((prev) => ({
          ...prev,
          firstName: "First name is required",
        }));
      } else {
        const deleteError = { ...inputErrors };
        delete deleteError.firstName;
        setInputErrors(deleteError);
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value.trim().length === 0) {
        setInputErrors((prev) => ({
          ...prev,
          lastName: "Last name is required",
        }));
      } else {
        const deleteError = { ...inputErrors };
        delete deleteError.lastName;
        setInputErrors(deleteError);
      }
    }
    if (e.target.name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(e.target.value);
      if (e.target.value.trim().length === 0) {
        setInputErrors((prev) => ({
          ...prev,
          email: "Email is required",
        }));
      } else if (!isValidEmail) {
        setInputErrors((prev) => ({
          ...prev,
          email: "Email is not valid",
        }));
      } else {
        const deleteError = { ...inputErrors };
        delete deleteError.email;
        setInputErrors(deleteError);
      }
    }
    if (e.target.name === "password") {
      if (e.target.value.trim().length === 0) {
        setInputErrors((prev) => ({
          ...prev,
          password: "Password is required",
        }));
      } else {
        const deleteError = { ...inputErrors };
        delete deleteError.password;
        setInputErrors(deleteError);
      }
    }
    if (e.target.name === "balance") {
      let value = e.target.value.replace(/[^0-9.]/g, "");
      value = Number(value);

      if (isNaN(value)) {
        setInputErrors((prev) => ({
          ...prev,
          balance: "Balance must be a number",
        }));
      } else if (value < 0) {
        setInputErrors((prev) => ({
          ...prev,
          balance: "Balance must not be negative",
        }));
      } else {
        const deleteError = { ...inputErrors };
        delete deleteError.balance;
        setInputErrors(deleteError);
        setFormData((prev) => ({
          ...prev,
          [e.target.name]: value * 1,
        }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const isFormDisabled =
    rfidTags.length === 0 ||
    isSubmitting ||
    Object.keys(inputErrors).length > 0 ||
    Object.keys(formData).length === 0 ||
    !formData.firstName ||
    (formData.firstName && formData.firstName.trim().length === 0) ||
    !formData.lastName ||
    (formData.lastName && formData.lastName.trim().length === 0) ||
    !formData.email ||
    (formData.email && formData.email.trim().length === 0) ||
    !formData.password ||
    (formData.password && formData.password.trim().length === 0) ||
    !formData.balance ||
    !formData.numberPlate ||
    (formData.numberPlate && formData.numberPlate.trim().length === 0) ||
    !formData.tagUid ||
    (formData.tagUid && formData.tagUid.trim().length === 0);

  const fetchRfidTags = async () => {
    setFetchingRfidTags(true);
    try {
      const { data } = await axios.get(
        `/parkings/${parkingId}/tags/notassigned`,
      );
      if (data?.rfidTags && data.rfidTags.length > 0) {
        setRfidTags(data.rfidTags);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setFetchingRfidTags(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setApiErrorMessage(null);
    try {
      const { data } = await axios.post("/users/register", formData);
      setIsSubmitting(false);
      setFormData({});
      setInputErrors({});
      navigate("/users");
    } catch (err) {
      console.error(err.response.data.message);
      setApiErrorMessage(err.response.data.message);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchRfidTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4 m-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <form>
        <div className="mb-3">
          <label
            htmlFor="firstName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData?.firstName || ""}
            className={`bg-gray-50 border ${
              rfidTags.length === 0 || isSubmitting
                ? "border-gray-300"
                : "border-blue-500"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              rfidTags.length === 0 || isSubmitting
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onChange={handleChange}
            disabled={rfidTags.length === 0 && isSubmitting}
            placeholder="John"
            required
          />
          {inputErrors?.firstName && (
            <p className="text-red-500 text-sm mt-1">{inputErrors.firstName}</p>
          )}
        </div>
        <div className="mb-3">
          <label
            htmlFor="lastName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData?.lastName || ""}
            className={`bg-gray-50 border ${
              rfidTags.length === 0 || isSubmitting
                ? "border-gray-300"
                : "border-blue-500"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              rfidTags.length === 0 || isSubmitting
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onChange={handleChange}
            disabled={rfidTags.length === 0 && isSubmitting}
            placeholder="Doe"
            required
          />
          {inputErrors?.lastName && (
            <p className="text-red-500 text-sm mt-1">{inputErrors.lastName}</p>
          )}
        </div>

        <div className="mb-3">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData?.email || ""}
            className={`bg-gray-50 border ${
              rfidTags.length === 0 || isSubmitting
                ? "border-gray-300"
                : "border-blue-500"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              rfidTags.length === 0 || isSubmitting
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onChange={handleChange}
            disabled={rfidTags.length === 0 && isSubmitting}
            placeholder="someone@example.com"
            required
          />
          {inputErrors?.email && (
            <p className="text-red-500 text-sm mt-1">{inputErrors.email}</p>
          )}
        </div>

        <div className="mb-3">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData?.password || ""}
            className={`bg-gray-50 border ${
              rfidTags.length === 0 || isSubmitting
                ? "border-gray-300"
                : "border-blue-500"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              rfidTags.length === 0 || isSubmitting
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onChange={handleChange}
            disabled={rfidTags.length === 0 && isSubmitting}
            placeholder="••••••••"
            required
          />
          {inputErrors?.password && (
            <p className="text-red-500 text-sm mt-1">{inputErrors.password}</p>
          )}
        </div>
        <div className="mb-3">
          <label
            htmlFor="balance"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Balance Number
          </label>
          <div>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-[14px] text-gray-500 dark:text-gray-400">
                DA
              </span>
              <input
                type="number"
                id="balance"
                name="balance"
                min={0}
                value={formData?.balance || ""}
                className={`bg-gray-50 pl-10 border ${
                  rfidTags.length === 0 || isSubmitting
                    ? "border-gray-300"
                    : "border-blue-500"
                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  rfidTags.length === 0 || isSubmitting
                    ? "cursor-not-allowed opacity-50"
                    : ""
                }`}
                onChange={handleChange}
                disabled={rfidTags.length === 0 && isSubmitting}
                placeholder="0.00"
                required
              />
            </div>
            {inputErrors?.balance && (
              <p className="text-red-500 text-sm mt-1">{inputErrors.balance}</p>
            )}
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="numberPlate"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            License Plate
          </label>
          <input
            type="text"
            id="numberPlate"
            name="numberPlate"
            value={formData?.numberPlate || ""}
            className={`bg-gray-50 border ${
              rfidTags.length === 0 || isSubmitting
                ? "border-gray-300"
                : "border-blue-500"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              rfidTags.length === 0 || isSubmitting
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onChange={handleChange}
            disabled={rfidTags.length === 0 && isSubmitting}
            placeholder="00451 115 28"
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="tagUid"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            RFID Tags
          </label>
          <select
            id="tagUid"
            name="tagUid"
            className={`bg-gray-50 border ${
              rfidTags.length === 0 || isSubmitting
                ? "border-gray-300"
                : "border-blue-500"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              rfidTags.length === 0 || isSubmitting
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            disabled={rfidTags.length === 0 && isSubmitting}
            defaultValue=""
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              { fetchingRfidTags ? "Fetching RFID Tags..." : "Select RFID Tag" }
            </option>
            {rfidTags.length > 0 &&
              rfidTags.map((tag) => (
                <option key={tag?.tagUid} value={tag?.tagUid}>
                  {tag?.tagUid}
                </option>
              ))}
          </select>
        </div>
        {apiErrorMessage && (
          <p className="text-red-500 text-md mt-1 mb-2">
            {apiErrorMessage}
          </p>
        )}
        {!fetchingRfidTags && rfidTags.length === 0 && (
          <p className="text-red-500 text-md mt-1 mb-2">
            No RFID Tags available to assign.
          </p>
        )}
        <button
          type="submit"
          className="text-white disabled:bg-gray-300 disabled:cursor-not-allowed bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center disabled:dark:bg-gray-600 disabled:dark:hover:bg-gray-700 disabled:dark:focus:ring-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={handleSubmit}
          disabled={isFormDisabled}
        >
          {isSubmitting ? "Adding User..." : "Add User"}
        </button>
      </form>
    </div>
  );
};

export default AddUser;
