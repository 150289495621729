import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import Home from "./pages/Home";
import Transactions from "./pages/Transactions";
import LogsActivity from "./pages/LogsActivity";
import RFIDTags from "./pages/RFIDTags";
import AddRFIDTag from "./pages/AddRFIDTag";
import { useState } from "react";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import Login from "./pages/Login";

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    document.body.classList.contains("dark") ||
      localStorage.getItem("dark") === "true",
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  if (isDarkMode) {
    document.body.classList.add("dark");
    localStorage.setItem("dark", true);
  } else {
    document.body.classList.remove("dark");
    localStorage.setItem("dark", false);
  }

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle("dark");
    localStorage.setItem("dark", !isDarkMode);
  };

  const shouldRenderLogin = localStorage.getItem("token") === null;

  return (
    <>
      <Router>
        <Header toggleMode={() => toggleDarkMode()} isDarkMode={isDarkMode} shouldRenderLogin={shouldRenderLogin} />
        {shouldRenderLogin ? (
          <>
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
            <Navigate to="/login" />
          </>
        ) : (
          <>
            <Header
              toggleMode={() => toggleDarkMode()}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              isDarkMode={isDarkMode}
            />
            <div className="flex pt-16 overflow-hidden min-h-screen	bg-gray-50 dark:bg-gray-900">
              <SideBar isSidebarOpen={isSidebarOpen} />
              <div className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-64 dark:bg-gray-900">
                <main className="px-4 pt-6">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/add" element={<AddUser />} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/logs-activity" element={<LogsActivity />} />
                    <Route path="/rfid-tags" element={<RFIDTags />} />
                    <Route path="/rfid-tags/add" element={<AddRFIDTag />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </main>
              </div>
            </div>
          </>
        )}
      </Router>
    </>
  );
};

export default App;
