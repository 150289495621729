import { Link } from "react-router-dom";
import { BsMoonFill, BsSunFill } from "react-icons/bs";
import { useState } from "react";

const Header = ({
  toggleMode,
  isDarkMode,
  shouldRenderLogin,
  setIsSidebarOpen,
  isSidebarOpen,
}) => {
  const user = JSON.parse(localStorage.getItem("admin"));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const signout = () => {
    localStorage.removeItem("admin");
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <nav className="fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            {!shouldRenderLogin && (
              <button
                id="toggleSidebarMobile"
                aria-expanded="true"
                aria-controls="sidebar"
                className="p-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              >
                {isSidebarOpen ? (
                  <svg
                    id="toggleSidebarMobileClose"
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    id="toggleSidebarMobileHamburger"
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            )}
            <Link to="/" className="flex ml-2 md:mr-24">
              <img
                src="/g2798.png"
                width={50}
                height={50}
                className="mr-3"
                alt="iPark Logo"
              />
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                iPark
              </span>
            </Link>
          </div>
          <div className="flex items-center">
            <button
              id="theme-toggle"
              data-tooltip-target="tooltip-toggle"
              type="button"
              className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
              onClick={toggleMode}
            >
              {isDarkMode ? <BsSunFill /> : <BsMoonFill />}
            </button>
            {!shouldRenderLogin && (
              <div className="flex items-center ml-3 relative">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    id="user-menu-button-2"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-2"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <img
                      className="rounded-full"
                      src={
                        user.profilePictureUrl || "https://picsum.photos/200"
                      }
                      width={32}
                      height={32}
                      alt="user avatar"
                    />
                  </button>
                </div>
                {isDropdownOpen && (
                  <div
                    className="z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    id="dropdown-2"
                    style={{
                      position: "absolute",
                      margin: 0,
                      top: 55,
                      right: 0,
                    }}
                    data-popper-placement="bottom"
                  >
                    <div className="px-4 py-3" role="none">
                      <p
                        className="text-sm text-gray-900 dark:text-white"
                        role="none"
                      >
                        {user.firstName} {user.lastName}
                      </p>
                      <p
                        className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                        role="none"
                      >
                        {user.email}
                      </p>
                    </div>
                    <ul className="py-1" role="none">
                      <li
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                        role="menuitem"
                        onClick={signout}
                      >
                        Sign out
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
