import { useState } from "react";
import axios from "../utils/axios";

const Login = () => {
  const [formData, setFormData] = useState({
    adminLogin: true,
  });
  const [inputErrors, setInputErrors] = useState({});
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setApiErrorMessage(null);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(e.target.value);
    if (e.target.name === "email" && e.target.value.trim().length === 0) {
      setInputErrors((prev) => ({ ...prev, email: "Email is required!" }));
    } else if (
      e.target.name === "email" &&
      e.target.value.trim().length > 0 &&
      !isValidEmail
    ) {
      setInputErrors((prev) => ({ ...prev, email: "Email is invalid!" }));
    } else if (
      e.target.name === "email" &&
      e.target.value.trim().length > 0 &&
      isValidEmail
    ) {
      const deleteError = { ...inputErrors };
      delete deleteError.email;
      setInputErrors((prev) => ({ ...deleteError }));
    }
    if (e.target.name === "password" && e.target.value.trim().length === 0) {
      setInputErrors((prev) => ({
        ...prev,
        password: "Password is required!",
      }));
    } else if (
      e.target.name === "password" &&
      e.target.value.trim().length > 0
    ) {
      const deleteError = { ...inputErrors };
      delete deleteError.password;
      setInputErrors((prev) => ({ ...deleteError }));
    }
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const { data } = await axios.post("/users/login", formData);
      setIsSubmitting(false);
      setFormData({});
      setInputErrors({});
      setApiErrorMessage(null);
      localStorage.setItem("token", data.token);
      localStorage.setItem("admin", JSON.stringify(data.admin));
      window.location.href = "/";
    } catch (err) {
      console.error(err.response.data);
      setApiErrorMessage(err.response.data.message);
      setIsSubmitting(false);
    }
  };

  const isFormDisabled =
    isSubmitting ||
    Object.keys(inputErrors).length > 0 ||
    Object.keys(formData).length === 0 ||
    !formData.email ||
    (formData.email && formData.email.trim().length === 0) ||
    !formData.password ||
    (formData.password && formData.password.trim().length === 0);

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img
          className="h-auto max-w-xs"
          src="https://res.cloudinary.com/dgmlaarf9/image/upload/v1702758920/frn8shipzp5lpcbvqdyh.png"
          alt="logo"
        />
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData?.email || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={handleChange}
                  placeholder="john.doe@example.com"
                  required
                />
                {inputErrors?.email && (
                  <p className="text-red-500 text-sm mt-1">
                    {inputErrors.email}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  value={formData?.password || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={handleChange}
                  required
                />
                {inputErrors?.password && (
                  <p className="text-red-500 text-sm mt-1">
                    {inputErrors.password}
                  </p>
                )}
              </div>
              {apiErrorMessage && (
                <p className="text-red-500 text-sm mt-1">{apiErrorMessage}</p>
              )}
              <button
                type="submit"
                className="text-white disabled:bg-gray-300 disabled:cursor-not-allowed bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center disabled:dark:bg-gray-600 disabled:dark:hover:bg-gray-700 disabled:dark:focus:ring-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                disabled={isFormDisabled}
                onClick={handleSubmit}
              >
                {isSubmitting ? "Signing in..." : "Sign in"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
